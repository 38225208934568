console.log('此项目已在Github上开源：https://github.com/mcmyth/home-page')
export default {
  beian: '粤ICP备18035696号',
  buttonList: [
    {
      img: '/assets/icon/qq.svg',
      title: 'QQ群',
      link: 'https://jq.qq.com/?_wv=1027&k=24hAeviP'
    },
    {
      img: '/assets/icon/github.svg',
      title: 'Github',
      link: 'https://github.com/mcmyth'
    },
    {
      img: '/assets/icon/codepen.svg',
      title: 'Codepen',
      link: 'https://codepen.io/MC_Myth'
    },
    {
      title: '博客',
      text: 'Blog',
      link: 'https://blog.mc-myth.cn'
    },
    {
      title: '小工具',
      text: '小工具',
      link: '/tool',
      _blank: false
    },
    {
      title: '友情链接',
      text: '朋友们',
      link: '/friend',
      _blank: false
    }
  ],
  friendLink: [
    {
      name: 'Ray',
      link: ['https://www.r-ay.cn'],
      description: '勿忘初心 方得始终'
    },
    {
      name: 'Lxns',
      link: ['https://blog.lxns.org', 'https://lxns.net'],
      description: 'Lxns牛逼'
    },
    {
      name: 'Lime',
      link: ['https://limecho.net'],
      description: 'Here are for you'
    },
    {
      name: 'LittleC',
      link: ['https://ltlec.com'],
      description: 'Material Design好'
    },
    {
      name: 'yanshiqwq',
      link: ['http://blog.yanshiqwq.ml/']
    },
    {
      name: 'Undefined',
      link: ['https://undefined.moe']
    },
    {
      name: '悠静',
      link: ['https://www.mikuhl.cn/'],
      description: '一位很漂亮的小姐姐'
    }
  ]
}
